const kastanienalle = [
  { lat: 52.5343057413324, lng: 13.4054944373012, weight: 5 },
  { lat: 52.5343743559578, lng: 13.4058375372468, weight: 25 },

  { lat: 52.5345143362229, lng: 13.4057897492929, weight: 12 },
  { lat: 52.5345878445308, lng: 13.4061336253529, weight: 9 },

  { lat: 52.5349055394162, lng: 13.4061319794959, weight: 4 },

  { lat: 52.5351371604995, lng: 13.4063252207207, weight: 7 },

  { lat: 52.5354236189206, lng: 13.4067017552765, weight: 1 },
  { lat: 52.5358835037248, lng: 13.4070761379301, weight: 26 },

  { lat: 52.5361224297673, lng: 13.4073748226762, weight: 10 },
  { lat: 52.5361567050757, lng: 13.4076828200783, weight: 40 },

  { lat: 52.5364566587728, lng: 13.4076837665392, weight: 28 },

  { lat: 52.5365640322124, lng: 13.4078111951113, weight: 19 },

  { lat: 52.5366592991231, lng: 13.407926334337, weight: 8 },

  { lat: 52.5366690066882, lng: 13.40845867917, weight: 6 },

  { lat: 52.5369198017049, lng: 13.4081132645492, weight: 7 },
  { lat: 52.5369673249837, lng: 13.408213819106, weight: 6 },

  { lat: 52.5375629258571, lng: 13.4088297070872, weight: 14 },

  { lat: 52.5377698772141, lng: 13.4090692559548, weight: 22 },

  { lat: 52.5379453495225, lng: 13.4096633230118, weight: 36 },

  { lat: 52.5381883776953, lng: 13.4094628379613, weight: 19 },
  { lat: 52.5385308752785, lng: 13.4098145825019, weight: 10 },

  { lat: 52.5389265214016, lng: 13.4102313070859, weight: 38 },

  { lat: 52.5391538974492, lng: 13.4103460791674, weight: 12 },

  { lat: 52.5393629446716, lng: 13.410975523008, weight: 6 },
  { lat: 52.5393499220407, lng: 13.4111612083984, weight: 15 },
  { lat: 52.5395804453243, lng: 13.4114560207241, weight: 10 },
  { lat: 52.5397643458943, lng: 13.4110166087307, weight: 9 },
  { lat: 52.5398565184759, lng: 13.411312239634, weight: 8 },

  { lat: 52.5399009180103, lng: 13.4112643359816, weight: 14 },
  { lat: 52.5402100177125, lng: 13.4117274529189, weight: 12 },

  { lat: 52.5403137915142, lng: 13.4117448067608, weight: 12 },
  { lat: 52.5407406152722, lng: 13.4114418359858, weight: 20 },

  { lat: 52.5405660668877, lng: 13.411206678844, weight: 15 },
  { lat: 52.5405848191768, lng: 13.4109936229838, weight: 20 },
  { lat: 52.540331667909, lng: 13.4110196499436, weight: 53 },
  { lat: 52.5402242924805, lng: 13.4109672236785, weight: 11 },
  { lat: 52.539586544108, lng: 13.4103112941102, weight: 37 },
  { lat: 52.5394979489357, lng: 13.4102411100447, weight: 8 },
  { lat: 52.5394422440387, lng: 13.4096029905403, weight: 55 },
  { lat: 52.5392219972054, lng: 13.4098812791717, weight: 10 },
  { lat: 52.5391030946877, lng: 13.4098900934468, weight: 7 },
  { lat: 52.539111295264, lng: 13.4098501067612, weight: 5 },
  { lat: 52.5390797226825, lng: 13.4095262662066, weight: 9 },
  { lat: 52.5391331719921, lng: 13.4092848788438, weight: 11 },
  { lat: 52.5392388499338, lng: 13.4091544389538, weight: 8 },
  { lat: 52.5392581960762, lng: 13.4090058236795, weight: 21 },
  { lat: 52.5393717748384, lng: 13.408793239285, weight: 9 },

  { lat: 52.5393404176489, lng: 13.4086520635555, weight: 8 },
  { lat: 52.5394601680938, lng: 13.4084733931143, weight: 16 },

  { lat: 52.5394929195442, lng: 13.4082694070847, weight: 9 },
  { lat: 52.5395835170249, lng: 13.4082180249668, weight: 16 },
  { lat: 52.5397096208441, lng: 13.4079392794984, weight: 28 },
  { lat: 52.5397985696191, lng: 13.4073582788434, weight: 26 },
  { lat: 52.539880041429, lng: 13.4073477927845, weight: 33 },
  { lat: 52.5399871419132, lng: 13.4071709772101, weight: 32 },

  { lat: 52.5400587194951, lng: 13.4068864794973, weight: 20 },
  { lat: 52.5403019410218, lng: 13.4060424772097, weight: 26 },
  { lat: 52.5403516147555, lng: 13.4058356889306, weight: 20 },
  { lat: 52.5404115898759, lng: 13.4055944019675, weight: 10 },
  { lat: 52.5406000416356, lng: 13.4053685496198, weight: 10 },

  { lat: 52.5401589921859, lng: 13.4052784778643, weight: 12 },
  { lat: 52.5400576316627, lng: 13.4058934065124, weight: 17 },
  { lat: 52.5399314658535, lng: 13.4055892762349, weight: 21 },
  { lat: 52.539940393938, lng: 13.4058691658773, weight: 15 },
  { lat: 52.5398309926223, lng: 13.4060398645509, weight: 25 },
  { lat: 52.5397709430886, lng: 13.4063383519106, weight: 32 },

  { lat: 52.5397257212193, lng: 13.406504383174, weight: 10 },
  { lat: 52.5396368963449, lng: 13.4067370539286, weight: 11 },

  { lat: 52.5393837459907, lng: 13.4066666207223, weight: 23 },
  { lat: 52.5392704461825, lng: 13.4069239496218, weight: 20 },
  { lat: 52.5392179709599, lng: 13.4071458752918, weight: 30 },

  { lat: 52.5392174181791, lng: 13.4073508346659, weight: 23 },
  { lat: 52.5390476956497, lng: 13.407466477865, weight: 22 },
  { lat: 52.5390305977181, lng: 13.4076677080707, weight: 18 },
  { lat: 52.5389714466105, lng: 13.4080792363121, weight: 20 },
  { lat: 52.5390596020857, lng: 13.4083347107015, weight: 14 },

  { lat: 52.5389317988721, lng: 13.4084836924691, weight: 18 },
  { lat: 52.538813098721, lng: 13.4085392791695, weight: 33 },
  { lat: 52.5387212726656, lng: 13.4092659944415, weight: 10 },
  { lat: 52.53873432383, lng: 13.4092659951128, weight: 8 },
  { lat: 52.5386030998935, lng: 13.4091479213658, weight: 8 },
  { lat: 52.5384325437916, lng: 13.4089672759092, weight: 15 },
  { lat: 52.5384635227188, lng: 13.4088206359863, weight: 17 },
  { lat: 52.5383859973315, lng: 13.4087194356562, weight: 4 },
  { lat: 52.5385733730001, lng: 13.4081233233447, weight: 31 },
  { lat: 52.5381691981991, lng: 13.408654806762, weight: 12 },
  { lat: 52.5380357006207, lng: 13.4086111236802, weight: 9 },
  { lat: 52.5378386241667, lng: 13.4084932226775, weight: 45 },
  { lat: 52.5376401280524, lng: 13.4086015951114, weight: 34 },
  { lat: 52.5378439502742, lng: 13.4077450652115, weight: 32 },
  { lat: 52.5377383596992, lng: 13.4078914691947, weight: 14 },
  { lat: 52.5374401012607, lng: 13.4079414911833, weight: 27 },

  { lat: 52.5373218744456, lng: 13.4080277931142, weight: 30 },
  { lat: 52.5372546242175, lng: 13.4078281191075, weight: 12 },
  { lat: 52.5371189482128, lng: 13.4077972372493, weight: 29 },
  { lat: 52.5370590356703, lng: 13.4074882073555, weight: 7 },
  { lat: 52.536866602272, lng: 13.4074610067616, weight: 13 },
  { lat: 52.5367170038941, lng: 13.4071256333918, weight: 13 },
  { lat: 52.5364702816858, lng: 13.4070247499448, weight: 21 },
  { lat: 52.5358478345806, lng: 13.40632705292, weight: 18 },
  { lat: 52.535741331019, lng: 13.4062764226776, weight: 20 },
  { lat: 52.5355935357567, lng: 13.4062684240136, weight: 21 },
  { lat: 52.5355200586103, lng: 13.4060002074156, weight: 17 },
  { lat: 52.5353400588231, lng: 13.4059014652127, weight: 19 },
  { lat: 52.5351951109753, lng: 13.4058566525824, weight: 9 },

  { lat: 52.5349178825786, lng: 13.4055095486436, weight: 8 },
  { lat: 52.5348159596215, lng: 13.4054866097106, weight: 16 },

  { lat: 52.5347172308093, lng: 13.4052959479973, weight: 20 },
  { lat: 52.5346424870243, lng: 13.4052436233455, weight: 20 },
  { lat: 52.5345219876499, lng: 13.4051733944452, weight: 8 },
  { lat: 52.5344342664841, lng: 13.4050101243486, weight: 8 },
];

const sredzkistrasse = [
  { lat: 52.537468, lng: 13.422616, weight: 17 },
  { lat: 52.537542, lng: 13.422408, weight: 6 },
  { lat: 52.537608, lng: 13.422222, weight: 13 },

  { lat: 52.537943, lng: 13.421283, weight: 16 },
  { lat: 52.538023, lng: 13.42106, weight: 10 },
  { lat: 52.538102, lng: 13.42084, weight: 10 },
  { lat: 52.538183, lng: 13.420613, weight: 6 },

  { lat: 52.5376085461163, lng: 13.4227548827684, weight: 14 },
  { lat: 52.538363, lng: 13.419532, weight: 8 },
  { lat: 52.538369, lng: 13.419303, weight: 21 },
  { lat: 52.538385, lng: 13.419116, weight: 21 },
  { lat: 52.538396, lng: 13.418898, weight: 11 },
  { lat: 52.538407, lng: 13.418668, weight: 8 },
  { lat: 52.53842, lng: 13.418427, weight: 11 },
  { lat: 52.538431, lng: 13.418205, weight: 11 },
  { lat: 52.538442, lng: 13.417984, weight: 11 },
  { lat: 52.538479, lng: 13.417291, weight: 12 },
  { lat: 52.538491, lng: 13.417074, weight: 5 },
  { lat: 52.538503, lng: 13.416859, weight: 10 },
  { lat: 52.538516, lng: 13.416627, weight: 10 },

  { lat: 52.538549, lng: 13.416021, weight: 10 },
  { lat: 52.538563, lng: 13.415757, weight: 9 },
  { lat: 52.538586, lng: 13.415333, weight: 8 },
  { lat: 52.538586, lng: 13.415333, weight: 8 },

  { lat: 52.537912, lng: 13.412909, weight: 18 },
  { lat: 52.537956, lng: 13.413158, weight: 18 },
  { lat: 52.538012, lng: 13.413468, weight: 12 },

  { lat: 52.538182, lng: 13.414416, weight: 24 },
  { lat: 52.538218, lng: 13.414621, weight: 23 },
  { lat: 52.538258, lng: 13.414842, weight: 6 },
  { lat: 52.538285, lng: 13.415527, weight: 12 },
  { lat: 52.538267, lng: 13.415868, weight: 8 },
  { lat: 52.538254, lng: 13.4161, weight: 30 },
  { lat: 52.538238, lng: 13.416392, weight: 23 },
  { lat: 52.538224, lng: 13.416655, weight: 18 },

  { lat: 52.53821, lng: 13.416919, weight: 8 },
  { lat: 52.538193, lng: 13.417232, weight: 11 },
  { lat: 52.538153, lng: 13.417941, weight: 7 },
  { lat: 52.538138, lng: 13.41823, weight: 29 },
  { lat: 52.538126, lng: 13.41845, weight: 29 },
  { lat: 52.538117, lng: 13.418621, weight: 23 },
  { lat: 52.538108, lng: 13.418812, weight: 9 },
  { lat: 52.538097, lng: 13.419018, weight: 11 },
  { lat: 52.538086, lng: 13.419222, weight: 7 },
  { lat: 52.538073, lng: 13.419477, weight: 9 },
  { lat: 52.537932, lng: 13.420358, weight: 8 },
  { lat: 52.537838, lng: 13.420622, weight: 12 },
  { lat: 52.537764, lng: 13.420827, weight: 8 },
  { lat: 52.537683, lng: 13.421054, weight: 10 },
  { lat: 52.537464, lng: 13.42167, weight: 21 },
  { lat: 52.537346, lng: 13.422004, weight: 8 },
  { lat: 52.537263, lng: 13.422244, weight: 14 },
  { lat: 52.537206, lng: 13.422399, weight: 27 },
];

const kollwitzstrasse = [
  { lat: 52.532906, lng: 13.413914, weight: 18 },
  { lat: 52.533029, lng: 13.414051, weight: 24 },

  { lat: 52.533991, lng: 13.415116, weight: 17 },

  { lat: 52.534127, lng: 13.415266, weight: 16 },

  { lat: 52.534211, lng: 13.41536, weight: 13 },
  { lat: 52.534323, lng: 13.415465, weight: 14 },
  { lat: 52.534456, lng: 13.41563, weight: 10 },
  { lat: 52.53454, lng: 13.415724, weight: 16 },
  { lat: 52.534646, lng: 13.41584, weight: 5 },
  { lat: 52.534771, lng: 13.416002, weight: 19 },
  { lat: 52.534959, lng: 13.4162, weight: 26 },

  { lat: 52.535081, lng: 13.416322, weight: 32 },
  { lat: 52.535278, lng: 13.416541, weight: 22 },
  { lat: 52.535397, lng: 13.416672, weight: 13 },
  { lat: 52.536987, lng: 13.418447, weight: 19 },
  { lat: 52.537083, lng: 13.418555, weight: 21 },

  { lat: 52.537287, lng: 13.418783, weight: 21 },

  { lat: 52.537383, lng: 13.418889, weight: 20 },

  { lat: 52.537583, lng: 13.419112, weight: 17 },
  { lat: 52.537731, lng: 13.419278, weight: 20 },
  { lat: 52.53783, lng: 13.419389, weight: 11 },
  { lat: 52.537987, lng: 13.419564, weight: 6 },

  { lat: 52.538386, lng: 13.420002, weight: 16 },
  { lat: 52.538537, lng: 13.42017, weight: 28 },
  { lat: 52.538736, lng: 13.420392, weight: 24 },
  { lat: 52.538851, lng: 13.42052, weight: 28 },
  { lat: 52.539022, lng: 13.42071, weight: 19 },
  { lat: 52.53913, lng: 13.42083, weight: 12 },
  { lat: 52.539268, lng: 13.420988, weight: 11 },

  { lat: 52.539243, lng: 13.421685, weight: 8 },
  { lat: 52.539125, lng: 13.421553, weight: 9 },
  { lat: 52.539017, lng: 13.421432, weight: 16 },
  { lat: 52.538897, lng: 13.421299, weight: 7 },

  { lat: 52.538829, lng: 13.421222, weight: 7 },
  { lat: 52.538665, lng: 13.421039, weight: 4 },
  { lat: 52.538587, lng: 13.420951, weight: 11 },
  { lat: 52.538467, lng: 13.420818, weight: 6 },
  { lat: 52.538292, lng: 13.420623, weight: 12 },
  { lat: 52.537912, lng: 13.420198, weight: 11 },
  { lat: 52.537769, lng: 13.420039, weight: 13 },
  { lat: 52.537662, lng: 13.419919, weight: 35 },
  { lat: 52.537466, lng: 13.419701, weight: 34 },
  { lat: 52.537315, lng: 13.419533, weight: 18 },
  { lat: 52.537169, lng: 13.41937, weight: 22 },

  { lat: 52.537021, lng: 13.419205, weight: 25 },
  { lat: 52.536928, lng: 13.419101, weight: 11 },
  { lat: 52.536244, lng: 13.418343, weight: 13 },
  { lat: 52.536096, lng: 13.41818, weight: 18 },
  { lat: 52.53593, lng: 13.417992, weight: 26 },
  { lat: 52.535758, lng: 13.417803, weight: 18 },
  { lat: 52.535592, lng: 13.417606, weight: 12 },
  { lat: 52.535464, lng: 13.417394, weight: 4 },

  { lat: 52.535464, lng: 13.417394, weight: 8 },
  { lat: 52.534884, lng: 13.416831, weight: 12 },
  { lat: 52.534725, lng: 13.416654, weight: 11 },
  { lat: 52.53457, lng: 13.41648, weight: 14 },
  { lat: 52.534396, lng: 13.416287, weight: 30 },
  { lat: 52.534234, lng: 13.416103, weight: 16 },
  { lat: 52.534147, lng: 13.416009, weight: 15 },

  { lat: 52.534004, lng: 13.41585, weight: 15 },

  { lat: 52.533912, lng: 13.415747, weight: 6 },

  { lat: 52.533809, lng: 13.415636, weight: 18 },
  { lat: 52.532932, lng: 13.414663, weight: 24 },
  { lat: 52.532789, lng: 13.414506, weight: 7 },
  { lat: 52.53268, lng: 13.41438, weight: 11 },
  { lat: 52.532553, lng: 13.414238, weight: 11 },
];

const kreuzberg = [
  { lat: 52.500031, lng: 13.419207, weight: 9 },
  { lat: 52.500743, lng: 13.419125, weight: 8 },

  { lat: 52.500865, lng: 13.419213, weight: 16 },

  { lat: 52.500962, lng: 13.419283, weight: 9 },
  { lat: 52.501386, lng: 13.419578, weight: 6 },
  { lat: 52.50153, lng: 13.41968, weight: 9 },
  { lat: 52.501672, lng: 13.419782, weight: 9 },
  { lat: 52.501807, lng: 13.419877, weight: 11 },
  { lat: 52.501922, lng: 13.419959, weight: 10 },
  { lat: 52.502043, lng: 13.420045, weight: 8 },
  { lat: 52.502262, lng: 13.420337, weight: 9 },
  { lat: 52.50219, lng: 13.420603, weight: 10 },
  { lat: 52.502128, lng: 13.420828, weight: 10 },
  { lat: 52.502062, lng: 13.421081, weight: 14 },
  { lat: 52.502, lng: 13.421299, weight: 4 },
  { lat: 52.501968, lng: 13.421414, weight: 11 },
  { lat: 52.502211, lng: 13.421596, weight: 11 },
  { lat: 52.502228, lng: 13.422554, weight: 11 },
  { lat: 52.501836, lng: 13.421897, weight: 20 },
  { lat: 52.502228, lng: 13.422554, weight: 22 },
  { lat: 52.501652, lng: 13.422572, weight: 10 },
  { lat: 52.501588, lng: 13.422806, weight: 8 },
  { lat: 52.501532, lng: 13.42301, weight: 7 },
  { lat: 52.501475, lng: 13.42322, weight: 14 },
  { lat: 52.501407, lng: 13.423468, weight: 26 },
  { lat: 52.501072, lng: 13.424708, weight: 17 },

  { lat: 52.501224, lng: 13.423229, weight: 10 },
  { lat: 52.501267, lng: 13.423072, weight: 12 },
  { lat: 52.501314, lng: 13.422905, weight: 20 },
  { lat: 52.501358, lng: 13.422745, weight: 8 },
  { lat: 52.501464, lng: 13.422359, weight: 16 },
  { lat: 52.50154, lng: 13.422083, weight: 20 },
  { lat: 52.501592, lng: 13.421891, weight: 13 },
  { lat: 52.501637, lng: 13.421727, weight: 17 },
  { lat: 52.50168, lng: 13.421567, weight: 16 },
  { lat: 52.50168, lng: 13.421567, weight: 17 },
  { lat: 52.501769, lng: 13.421242, weight: 8 },
  { lat: 52.501936, lng: 13.42063, weight: 23 },
  { lat: 52.501994, lng: 13.42042, weight: 8 },
  { lat: 52.502055, lng: 13.420196, weight: 9 },

  { lat: 52.50239, lng: 13.420292, weight: 8 },
  { lat: 52.502578, lng: 13.420426, weight: 20 },

  { lat: 52.502857, lng: 13.420625, weight: 12 },
  { lat: 52.50301, lng: 13.420733, weight: 10 },

  { lat: 52.503548, lng: 13.420712, weight: 7 },
  { lat: 52.503689, lng: 13.420813, weight: 18 },
  { lat: 52.503908, lng: 13.420467, weight: 17 },
  { lat: 52.503979, lng: 13.42102, weight: 14 },
  { lat: 52.504118, lng: 13.421119, weight: 12 },
  { lat: 52.504247, lng: 13.421211, weight: 28 },
  { lat: 52.504368, lng: 13.421298, weight: 20 },

  { lat: 52.503473, lng: 13.420206, weight: 11 },
  { lat: 52.503522, lng: 13.420027, weight: 14 },
  { lat: 52.503581, lng: 13.419813, weight: 14 },
  { lat: 52.503855, lng: 13.420248, weight: 16 },
  { lat: 52.503699, lng: 13.419386, weight: 12 },

  { lat: 52.503797, lng: 13.41928, weight: 10 },
  { lat: 52.503801, lng: 13.419017, weight: 15 },
  { lat: 52.503854, lng: 13.418827, weight: 10 },
  { lat: 52.503907, lng: 13.418633, weight: 12 },
  { lat: 52.503959, lng: 13.418446, weight: 14 },
  { lat: 52.50401, lng: 13.418263, weight: 20 },
  { lat: 52.504064, lng: 13.418066, weight: 12 },
  { lat: 52.504123, lng: 13.417848, weight: 6 },
  { lat: 52.504233, lng: 13.417842, weight: 12 },
  { lat: 52.504366, lng: 13.417938, weight: 14 },
  { lat: 52.504473, lng: 13.418015, weight: 17 },
  { lat: 52.504546, lng: 13.418309, weight: 22 },
  { lat: 52.504717, lng: 13.418382, weight: 18 },
  { lat: 52.50489, lng: 13.41837, weight: 11 },

  { lat: 52.505002, lng: 13.418874, weight: 12 },
  { lat: 52.50505, lng: 13.419088, weight: 18 },
  { lat: 52.505147, lng: 13.419262, weight: 10 },
  { lat: 52.505254, lng: 13.419338, weight: 8 },
  { lat: 52.505367, lng: 13.41942, weight: 12 },
  { lat: 52.503858, lng: 13.417905, weight: 14 },
  { lat: 52.503802, lng: 13.418111, weight: 8 },
  { lat: 52.503748, lng: 13.418308, weight: 18 },
  { lat: 52.503691, lng: 13.418517, weight: 19 },
  { lat: 52.503419, lng: 13.418746, weight: 21 },
  { lat: 52.503443, lng: 13.419428, weight: 10 },
  { lat: 52.503377, lng: 13.419668, weight: 13 },
  { lat: 52.50332, lng: 13.419877, weight: 21 },
  { lat: 52.503259, lng: 13.420099, weight: 10 },
  { lat: 52.503192, lng: 13.420344, weight: 8 },

  { lat: 52.503115, lng: 13.420402, weight: 10 },
  { lat: 52.502969, lng: 13.420299, weight: 18 },
  { lat: 52.502942, lng: 13.419806, weight: 22 },
  { lat: 52.502699, lng: 13.420108, weight: 19 },
  { lat: 52.502584, lng: 13.420027, weight: 7 },
  { lat: 52.50246, lng: 13.419939, weight: 10 },

  { lat: 52.502417, lng: 13.419776, weight: 3 },
  { lat: 52.502468, lng: 13.419579, weight: 10 },
  { lat: 52.502514, lng: 13.419405, weight: 9 },
  { lat: 52.502676, lng: 13.419367, weight: 11 },

  { lat: 52.502666, lng: 13.418825, weight: 7 },

  { lat: 52.502467, lng: 13.418687, weight: 12 },
  { lat: 52.502399, lng: 13.4189, weight: 16 },
  { lat: 52.502358, lng: 13.419056, weight: 9 },
  { lat: 52.502306, lng: 13.419258, weight: 14 },
  { lat: 52.502257, lng: 13.419443, weight: 16 },
  { lat: 52.502213, lng: 13.419614, weight: 9 },
  { lat: 52.502125, lng: 13.419702, weight: 4 },
  { lat: 52.502, lng: 13.419613, weight: 14 },
  { lat: 52.501869, lng: 13.41952, weight: 19 },
  { lat: 52.501726, lng: 13.419419, weight: 8 },
  { lat: 52.501596, lng: 13.419327, weight: 9 },
  { lat: 52.501474, lng: 13.419241, weight: 6 },

  { lat: 52.501304, lng: 13.419614, weight: 8 },
  { lat: 52.501238, lng: 13.419854, weight: 10 },

  { lat: 52.501179, lng: 13.420072, weight: 9 },

  { lat: 52.50093, lng: 13.420973, weight: 19 },
  { lat: 52.500834, lng: 13.421326, weight: 43 },
  { lat: 52.500777, lng: 13.421539, weight: 18 },
  { lat: 52.500706, lng: 13.421796, weight: 8 },
  { lat: 52.500649, lng: 13.422005, weight: 20 },
  { lat: 52.500558, lng: 13.422334, weight: 22 },
  { lat: 52.500499, lng: 13.42255, weight: 9 },
  { lat: 52.500535, lng: 13.422789, weight: 5 },
  { lat: 52.500576, lng: 13.422951, weight: 7 },
  { lat: 52.500393, lng: 13.423551, weight: 8 },
  { lat: 52.500393, lng: 13.423551, weight: 14 },
  { lat: 52.500295, lng: 13.423614, weight: 6 },
  { lat: 52.500173, lng: 13.423725, weight: 12 },
  { lat: 52.500089, lng: 13.424032, weight: 10 },
  { lat: 52.5, lng: 13.424355, weight: 16 },
  { lat: 52.499944, lng: 13.42456, weight: 16 },
  { lat: 52.499887, lng: 13.42477, weight: 16 },
  { lat: 52.499818, lng: 13.425019, weight: 7 },
  { lat: 52.49976, lng: 13.425228, weight: 15 },
  { lat: 52.499607, lng: 13.425789, weight: 24 },

  { lat: 52.499686, lng: 13.424596, weight: 6 },
  { lat: 52.499744, lng: 13.424383, weight: 5 },
  { lat: 52.499799, lng: 13.424185, weight: 8 },
  { lat: 52.499901, lng: 13.423811, weight: 6 },
  { lat: 52.499968, lng: 13.423522, weight: 20 },
  { lat: 52.499917, lng: 13.423323, weight: 7 },
  { lat: 52.499879, lng: 13.423173, weight: 5 },
  { lat: 52.499969, lng: 13.422635, weight: 8 },
  { lat: 52.500069, lng: 13.422574, weight: 8 },
  { lat: 52.500158, lng: 13.422517, weight: 6 },
  { lat: 52.500273, lng: 13.422437, weight: 6 },
  { lat: 52.500341, lng: 13.422218, weight: 8 },
  { lat: 52.500395, lng: 13.422018, weight: 10 },
  { lat: 52.50045, lng: 13.421814, weight: 23 },
  { lat: 52.500512, lng: 13.421588, weight: 29 },
  { lat: 52.500575, lng: 13.421362, weight: 20 },
  { lat: 52.500645, lng: 13.421107, weight: 12 },
  { lat: 52.500715, lng: 13.420853, weight: 6 },
  { lat: 52.500771, lng: 13.420649, weight: 8 },
  { lat: 52.500824, lng: 13.420454, weight: 12 },
  { lat: 52.500873, lng: 13.420276, weight: 8 },
  { lat: 52.500922, lng: 13.420098, weight: 10 },
  { lat: 52.500973, lng: 13.419912, weight: 11 },
  { lat: 52.501024, lng: 13.419725, weight: 14 },
  { lat: 52.501093, lng: 13.419476, weight: 14 },
];

const linienstrasse = [
  { lat: 52.528503, lng: 13.406227, weight: 30 },
  { lat: 52.52802, lng: 13.406439, weight: 19 },
  { lat: 52.528439, lng: 13.406871, weight: 9 },

  { lat: 52.528334, lng: 13.407837, weight: 2 },
  { lat: 52.5283, lng: 13.407963, weight: 12 },
  { lat: 52.528258, lng: 13.408367, weight: 20 },
  { lat: 52.528225, lng: 13.408616, weight: 6 },
  { lat: 52.525916, lng: 13.406963, weight: 26 },
  { lat: 52.527933, lng: 13.408026, weight: 10 },
  { lat: 52.527556, lng: 13.408275, weight: 9 },
  { lat: 52.527423, lng: 13.408171, weight: 12 },
  { lat: 52.527286, lng: 13.408059, weight: 20 },
  { lat: 52.527095, lng: 13.407906, weight: 9 },
  { lat: 52.526859, lng: 13.40772, weight: 5 },
  { lat: 52.526773, lng: 13.407654, weight: 9 },
  { lat: 52.526623, lng: 13.407544, weight: 11 },
  { lat: 52.526485, lng: 13.407406, weight: 8 },
  { lat: 52.526244, lng: 13.40721, weight: 12 },
  { lat: 52.525999, lng: 13.407027, weight: 17 },
  { lat: 52.525808, lng: 13.406881, weight: 4 },
  { lat: 52.525736, lng: 13.406825, weight: 21 },
  { lat: 52.525624, lng: 13.40674, weight: 7 },
  { lat: 52.525535, lng: 13.406677, weight: 15 },
  { lat: 52.525417, lng: 13.406575, weight: 18 },
  { lat: 52.525548, lng: 13.407063, weight: 28 },
  { lat: 52.52572, lng: 13.407204, weight: 17 },
  { lat: 52.525812, lng: 13.407285, weight: 16 },
  { lat: 52.525924, lng: 13.407379, weight: 7 },
  { lat: 52.526032, lng: 13.407469, weight: 16 },
  { lat: 52.526183, lng: 13.407593, weight: 10 },
  { lat: 52.526334, lng: 13.407725, weight: 9 },

  { lat: 52.52647, lng: 13.40783, weight: 15 },
  { lat: 52.526565, lng: 13.40792, weight: 5 },
  { lat: 52.526664, lng: 13.407989, weight: 5 },
  { lat: 52.526711, lng: 13.408029, weight: 3 },
  { lat: 52.526842, lng: 13.408137, weight: 10 },
  { lat: 52.526915, lng: 13.408232, weight: 19 },

  { lat: 52.528305, lng: 13.409597, weight: 9 },

  { lat: 52.528047, lng: 13.40991, weight: 6 },
  { lat: 52.528076, lng: 13.409705, weight: 18 },
  { lat: 52.528468, lng: 13.408252, weight: 8 },
  { lat: 52.528494, lng: 13.408062, weight: 10 },
  { lat: 52.528518, lng: 13.407883, weight: 12 },
  { lat: 52.528555, lng: 13.407577, weight: 9 },
  { lat: 52.528578, lng: 13.407385, weight: 12 },
  { lat: 52.528618, lng: 13.407053, weight: 14 },
  { lat: 52.528639, lng: 13.406852, weight: 8 },
  { lat: 52.528657, lng: 13.406712, weight: 14 },
  { lat: 52.528675, lng: 13.406529, weight: 16 },
  { lat: 52.5287, lng: 13.406364, weight: 10 },
  { lat: 52.528714, lng: 13.40624, weight: 5 },
  { lat: 52.52874, lng: 13.406027, weight: 8 },
  { lat: 52.528759, lng: 13.405871, weight: 4 },
  { lat: 52.528784, lng: 13.405675, weight: 18 },
  { lat: 52.528591, lng: 13.405534, weight: 14 },
  { lat: 52.528556, lng: 13.405912, weight: 28 },
];

export default [
  ...kastanienalle,
  ...sredzkistrasse,
  ...kollwitzstrasse,
  ...kreuzberg,
  ...linienstrasse,
];
