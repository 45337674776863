import config from "settings";
import { useRef, useState } from "react";
import { useSnackbar } from "notistack";

import UploadIcon from "@mui/icons-material/Upload";
import LinkIcon from "@mui/icons-material/Link";
import {
  IconButton,
  LinearProgress,
  ListItem,
  ListItemText,
} from "@mui/material";
import { uploadFile } from "api/files";
import { useMutation } from "api/useMutation";

const FileUpload = ({ name, filePath }: { name: string; filePath: string }) => {
  const setContactSettingsMutation = useMutation(uploadFile);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = async (event: any) => {
    if (!event.target.files[0]) {
      return;
    }
    setIsLoading(true);
    const data = await setContactSettingsMutation(
      filePath,
      event.target.files[0]
    );
    if (data.axiosResponse?.status === 200) {
      enqueueSnackbar("File is uploaded");
    } else {
      enqueueSnackbar("File upload failed");
    }
    setIsLoading(false);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
  };

  return (
    <>
      <ListItem
        secondaryAction={
          <>
            <IconButton
              edge="end"
              aria-label="upload"
              onClick={handleClick}
              disabled={isLoading}
            >
              <UploadIcon />
            </IconButton>

            <input
              type="file"
              onChange={handleChange} // ADDED
              ref={hiddenFileInput}
              style={{ display: "none" }}
            />

            <IconButton
              edge="end"
              aria-label="copy"
              style={{ marginLeft: 30 }}
              onClick={() => {
                navigator.clipboard.writeText(
                  encodeURI(`${config.fileDownloadPrefix}${filePath}`)
                );
                enqueueSnackbar("File URL has been copied to clipboard");
              }}
            >
              <LinkIcon />
            </IconButton>
          </>
        }
      >
        <ListItemText>{name}</ListItemText>
      </ListItem>
      {isLoading && <LinearProgress />}
    </>
  );
};

export default FileUpload;
