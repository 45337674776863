import { Link } from "@mui/material";

import "./styles.css";

const Footer = () => (
  <p className={"footer-container"}>
    <Link href={"https://thisisdenizen.com"} target={"_blank"}>
      thisisdenizen.com
    </Link>
  </p>
);

export default Footer;
