import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Box, Button, Grid, Typography } from "@mui/material";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import { useMutation } from "api/useMutation";
import { getLocationAccessCode, postLocationAccessCode } from "api/location";
import { LocationCodes, LocationCodesForm } from "types";
import moment from "moment";

import ErrorBanner from "components/ErrorBanner";
import config from "settings";

const AccessFrom: React.FC = () => {
  const [codesAndLocations, setCodesAndLocations] = React.useState<
    LocationCodes | undefined
  >();
  const [formData, setFormData] = React.useState<
    LocationCodesForm | undefined
  >();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingError, setLoadingError] = React.useState(false);
  const numaLocationId = config.numaLocationId;
  const postLocationAccessCodeMutation = useMutation(postLocationAccessCode);
  const getLocationAccessCodeMutation = useMutation(getLocationAccessCode);

  React.useEffect(() => {
    getLocationAccessCodeMutation(numaLocationId).then((res) => {
      const { axiosResponse } = res;
      const { status, data } = axiosResponse || {};
      if (status === 200 || status === 204) {
        setCodesAndLocations({ numa: data });
        setFormData({ numa: data?.code });
        setLoadingError(false);
      } else {
        setLoadingError(true);
      }
    });
  }, [formData?.numa]);

  if (loadingError) {
    return <ErrorBanner />;
  }

  if (!formData) {
    return null;
  }

  const { numa } = codesAndLocations || {};
  const { updated, updatedBy } = numa || {};
  const hasEmptyValue = updated === undefined && updatedBy === undefined;

  return (
    <Box>
      <Typography variant="h4">Access to locations</Typography>
      <Box py={2} />
      <Typography variant="h5">Denizen at Sketch</Typography>
      {!hasEmptyValue && (
        <div>
          Last updated: {moment(updated).from(moment())} by {updatedBy}
        </div>
      )}
      <Formik
        initialValues={formData}
        onSubmit={async (values: LocationCodesForm, { setSubmitting }) => {
          const result = await postLocationAccessCodeMutation(
            values,
            numaLocationId
          );

          if (result.error) {
            enqueueSnackbar("An error ocurred.");
            setLoadingError(true);
          } else {
            setCodesAndLocations({ numa: result.axiosResponse?.data });
            enqueueSnackbar("Changes were saved.");
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box py={1} />
                <Field name="numa" component={TextField} fullWidth />
                <ErrorMessage name="numa" component="div" />
                <Box py={1} />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AccessFrom;
