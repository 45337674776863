import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Accessibility from "@mui/icons-material/Accessibility";
import Folder from "@mui/icons-material/Folder";
import List from "@mui/material/List";
import ImportContacts from "@mui/icons-material/ImportContacts";

import React from "react";

const Menu: React.FC = () => {
  return (
    <List component="nav">
      <ListItemButton component={Link} to="/">
        <ListItemIcon>
          <ImportContacts />
        </ListItemIcon>
        <ListItemText primary="Contacts" />
      </ListItemButton>
      <ListItemButton component={Link} to="/weekly-menu">
        <ListItemIcon>
          <RestaurantIcon />
        </ListItemIcon>
        <ListItemText primary="Weekly menu" />
      </ListItemButton>
      <ListItemButton component={Link} to="/openingHours">
        <ListItemIcon>
          <AccessTimeIcon />
        </ListItemIcon>
        <ListItemText primary="Opening hours" />
      </ListItemButton>
      <ListItemButton component={Link} to="/products">
        <ListItemIcon>
          <QrCodeIcon />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </ListItemButton>
      <ListItemButton component={Link} to="/Access">
        <ListItemIcon>
          <Accessibility />
        </ListItemIcon>
        <ListItemText primary="Access" />
      </ListItemButton>
      <ListItemButton component={Link} to="/Files">
        <ListItemIcon>
          <Folder />
        </ListItemIcon>
        <ListItemText primary="Files" />
      </ListItemButton>
    </List>
  );
};

export default Menu;
