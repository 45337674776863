import React from "react";
import DownloadIcons from "../DownloadIcons";

const DownloadInstructions = ({ instructions, title }) => {
  return (
    <>
      <h1>{title}</h1>
      <div className={"border"} />
      <div className={"pageContent"}>
        {instructions}
        <DownloadIcons />
      </div>
    </>
  );
};

export default DownloadInstructions;
