import React, { useEffect, useState } from "react";
import { ContactSettings, Employee } from "types";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useMutation } from "api/useMutation";
import { contactsConfig } from "settings";

import {
  Box,
  Button,
  Grid,
  Select,
  Typography,
  MenuItem,
  InputLabel,
  Tabs,
  Tab,
} from "@mui/material";

import ErrorBanner from "components/ErrorBanner";
import { getSettings, setContactSettings } from "api/settings";
import { getEmployees } from "api/members";

const Contacts: React.FC = () => {
  const [initialVaues, setInitialValues] = React.useState<
    ContactSettings | undefined
  >();
  const [activeTab, setActiveTab] = useState<
    "kitchenDuty" | "artolContacts" | "eiswerkContacts" | "hqContacts"
  >("kitchenDuty");
  const [employees, setEmployees] = useState<Employee[]>([]);
  const setContactSettingsMutation = useMutation(setContactSettings);

  const [loadingError, setLoadingError] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getSettings().then((res) => {
      if (res.data) {
        setInitialValues(res.data.contactSettings);
      } else {
        setLoadingError(true);
      }
    });
    getEmployees().then((res) => {
      if (res.data) {
        setEmployees(res.data);
      } else {
        setLoadingError(true);
      }
    });
  }, []);

  if (loadingError) {
    return <ErrorBanner />;
  }

  if (!initialVaues) {
    return null;
  }

  const EmployeeDropdown = ({
    form,
    field,
    label,
    fullWidth,
  }: {
    form: any;
    field: { name?: string; value?: string };
    label: string | undefined;
    fullWidth: boolean | undefined;
  }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <>
        <InputLabel id={`select-label-${name}`}>{label}</InputLabel>
        <Select
          labelId={`select-label-${name}`}
          label={label}
          name={name}
          value={value}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
          fullWidth={fullWidth}
        >
          {[{ id: "", name: "None" }, ...employees].map((employee) => {
            return (
              <MenuItem key={employee.id} value={employee.id}>
                {employee.name}
              </MenuItem>
            );
          })}
        </Select>
      </>
    );
  };

  return (
    <Box>
      <Typography variant="h4">Contacts</Typography>
      <Tabs
        value={activeTab}
        onChange={(event, value) => setActiveTab(value)}
        aria-label="opening hours"
      >
        <Tab
          sx={{ textTransform: "none" }}
          label={"HQ Contacts"}
          value={"hqContacts"}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label={"Eiswerk contacts"}
          value={"eiswerkContacts"}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label={"Artol contacts"}
          value={"artolContacts"}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label={"Kitchen duty"}
          value={"kitchenDuty"}
        />
      </Tabs>

      <Box py={2} />
      <Formik
        initialValues={initialVaues}
        onSubmit={async (values: ContactSettings, { setSubmitting }) => {
          var payload: Partial<ContactSettings> = {};
          switch (activeTab) {
            case "kitchenDuty":
              payload = {
                artolKitchenDutyMemberId: values.artolKitchenDutyMemberId,
                eiswerkKitchenDutyMemberId: values.eiswerkKitchenDutyMemberId,
              };
              break;
            case "artolContacts":
              payload = {
                artolMembershipsMemberId: values.artolMembershipsMemberId,
                artolCommunityEventsMemberId:
                  values.artolCommunityEventsMemberId,
                artolSpacesOpsMemberId: values.artolSpacesOpsMemberId,
              };
              break;
            case "eiswerkContacts":
              payload = {
                eiswerkMembershipsMemberId: values.eiswerkMembershipsMemberId,
                eiswerkCommunityEventsMemberId:
                  values.eiswerkCommunityEventsMemberId,
                eiswerkSelfCareMemberId: values.eiswerkSelfCareMemberId,
                eiswerkSpacesOpsMemberId: values.eiswerkSpacesOpsMemberId,
              };
              break;
            case "hqContacts":
              payload = {
                spaceSupplyMemberId: values.spaceSupplyMemberId,
                technicalIssuesMemberId: values.technicalIssuesMemberId,
                spaceDemandMemberId: values.spaceDemandMemberId,
                investorsMemberId: values.investorsMemberId,
                feedbackMemberId: values.feedbackMemberId,
              };
              break;
          }
          const result = await setContactSettingsMutation(payload);
          if (result.error) {
            enqueueSnackbar("An error ocurred.");
          } else {
            enqueueSnackbar("Changes were saved.");
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {contactsConfig[activeTab].map((value) => (
                  <Box key={value.name} py={1}>
                    <Box>
                      <InputLabel htmlFor={value.name}>
                        {value.label}
                      </InputLabel>
                      <Field
                        name={value.name}
                        component={EmployeeDropdown}
                        fullWidth
                      />
                      <ErrorMessage name={value.name} component="div" />
                    </Box>
                  </Box>
                ))}

                <Box py={1} />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Contacts;
