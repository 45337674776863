const kastanienalle = [
  { lat: 52.5343743559578, lng: 13.4058375372468, weight: 1 },

  { lat: 52.5345143362229, lng: 13.4057897492929, weight: 2 },
  { lat: 52.5345878445308, lng: 13.4061336253529, weight: 1 },
  { lat: 52.5348278690495, lng: 13.4062541124285, weight: 1 },
  { lat: 52.5349055394162, lng: 13.4061319794959, weight: 1 },

  { lat: 52.5351371604995, lng: 13.4063252207207, weight: 1 },

  { lat: 52.5358835037248, lng: 13.4070761379301, weight: 11 },

  { lat: 52.5363829329043, lng: 13.4075402951093, weight: 6 },
  { lat: 52.5364566587728, lng: 13.4076837665392, weight: 2 },

  { lat: 52.5365640322124, lng: 13.4078111951113, weight: 1 },

  { lat: 52.5366690066882, lng: 13.40845867917, weight: 36 },

  { lat: 52.5369673249837, lng: 13.408213819106, weight: 1 },

  { lat: 52.5375629258571, lng: 13.4088297070872, weight: 5 },

  { lat: 52.5381883776953, lng: 13.4094628379613, weight: 1 },

  { lat: 52.5389265214016, lng: 13.4102313070859, weight: 3 },

  { lat: 52.5393064228915, lng: 13.4106059120892, weight: 5 },

  { lat: 52.5393629446716, lng: 13.410975523008, weight: 5 },

  { lat: 52.5394485944028, lng: 13.411757177864, weight: 5 },
  { lat: 52.5399009180103, lng: 13.4112643359816, weight: 3 },
  { lat: 52.5402100177125, lng: 13.4117274529189, weight: 2 },

  { lat: 52.5403137915142, lng: 13.4117448067608, weight: 5 },
  { lat: 52.5407406152722, lng: 13.4114418359858, weight: 2 },

  { lat: 52.5402242924805, lng: 13.4109672236785, weight: 3 },
  { lat: 52.539586544108, lng: 13.4103112941102, weight: 5 },
  { lat: 52.5394979489357, lng: 13.4102411100447, weight: 2 },
  { lat: 52.5394422440387, lng: 13.4096029905403, weight: 1 },

  { lat: 52.5393717748384, lng: 13.408793239285, weight: 4 },

  { lat: 52.5393404176489, lng: 13.4086520635555, weight: 1 },

  { lat: 52.5397985696191, lng: 13.4073582788434, weight: 2 },
  { lat: 52.539880041429, lng: 13.4073477927845, weight: 2 },
  { lat: 52.5399871419132, lng: 13.4071709772101, weight: 2 },

  { lat: 52.5403019410218, lng: 13.4060424772097, weight: 2 },

  { lat: 52.5400576316627, lng: 13.4058934065124, weight: 2 },

  { lat: 52.5398309926223, lng: 13.4060398645509, weight: 2 },

  { lat: 52.5397257212193, lng: 13.406504383174, weight: 2 },

  { lat: 52.5392179709599, lng: 13.4071458752918, weight: 2 },

  { lat: 52.5384325437916, lng: 13.4089672759092, weight: 1 },
  { lat: 52.5384635227188, lng: 13.4088206359863, weight: 1 },
  { lat: 52.5383859973315, lng: 13.4087194356562, weight: 1 },
  { lat: 52.5385733730001, lng: 13.4081233233447, weight: 2 },

  { lat: 52.5378386241667, lng: 13.4084932226775, weight: 2 },

  { lat: 52.5378439502742, lng: 13.4077450652115, weight: 1 },

  { lat: 52.5373218744456, lng: 13.4080277931142, weight: 10 },
  { lat: 52.5372546242175, lng: 13.4078281191075, weight: 4 },

  { lat: 52.5370590356703, lng: 13.4074882073555, weight: 13 },

  { lat: 52.5355935357567, lng: 13.4062684240136, weight: 1 },
  { lat: 52.5355200586103, lng: 13.4060002074156, weight: 5 },

  { lat: 52.5351951109753, lng: 13.4058566525824, weight: 2 },

  { lat: 52.5349178825786, lng: 13.4055095486436, weight: 1 },
];

const sredzkistrasse = [
  { lat: 52.537468, lng: 13.422616, weight: 1 },

  { lat: 52.537608, lng: 13.422222, weight: 1 },

  { lat: 52.537943, lng: 13.421283, weight: 1 },

  { lat: 52.538183, lng: 13.420613, weight: 2 },

  { lat: 52.538369, lng: 13.419303, weight: 3 },

  { lat: 52.538407, lng: 13.418668, weight: 2 },

  { lat: 52.538442, lng: 13.417984, weight: 2 },

  { lat: 52.538491, lng: 13.417074, weight: 2 },
  { lat: 52.538503, lng: 13.416859, weight: 1 },

  { lat: 52.538549, lng: 13.416021, weight: 1 },

  { lat: 52.538586, lng: 13.415333, weight: 1 },
  { lat: 52.538586, lng: 13.415333, weight: 1 },
  { lat: 52.53818, lng: 13.412607, weight: 32 },

  { lat: 52.538012, lng: 13.413468, weight: 1 },
  { lat: 52.538044, lng: 13.41365, weight: 1 },

  { lat: 52.538258, lng: 13.414842, weight: 1 },

  { lat: 52.538254, lng: 13.4161, weight: 1 },

  { lat: 52.538224, lng: 13.416655, weight: 1 },

  { lat: 52.538153, lng: 13.417941, weight: 2 },

  { lat: 52.538097, lng: 13.419018, weight: 1 },

  { lat: 52.538073, lng: 13.419477, weight: 3 },

  { lat: 52.537764, lng: 13.420827, weight: 1 },

  { lat: 52.537346, lng: 13.422004, weight: 2 },
  { lat: 52.537263, lng: 13.422244, weight: 1 },
];

const kollwitzstrasse = [
  { lat: 52.533991, lng: 13.415116, weight: 2 },

  { lat: 52.534211, lng: 13.41536, weight: 3 },

  { lat: 52.534456, lng: 13.41563, weight: 4 },
  { lat: 52.53454, lng: 13.415724, weight: 3 },
  { lat: 52.534646, lng: 13.41584, weight: 1 },
  { lat: 52.534771, lng: 13.416002, weight: 1 },
  { lat: 52.534959, lng: 13.4162, weight: 4 },

  { lat: 52.537287, lng: 13.418783, weight: 1 },

  { lat: 52.537583, lng: 13.419112, weight: 7 },

  { lat: 52.53783, lng: 13.419389, weight: 2 },

  { lat: 52.538537, lng: 13.42017, weight: 3 },
  { lat: 52.538736, lng: 13.420392, weight: 2 },

  { lat: 52.539022, lng: 13.42071, weight: 1 },

  { lat: 52.539268, lng: 13.420988, weight: 1 },

  { lat: 52.539125, lng: 13.421553, weight: 1 },
  { lat: 52.539017, lng: 13.421432, weight: 2 },

  { lat: 52.538665, lng: 13.421039, weight: 3 },

  { lat: 52.537466, lng: 13.419701, weight: 1 },
  { lat: 52.537315, lng: 13.419533, weight: 2 },
  { lat: 52.537169, lng: 13.41937, weight: 1 },

  { lat: 52.537021, lng: 13.419205, weight: 1 },

  { lat: 52.536096, lng: 13.41818, weight: 3 },
  { lat: 52.53593, lng: 13.417992, weight: 3 },

  { lat: 52.535592, lng: 13.417606, weight: 1 },
  { lat: 52.535464, lng: 13.417394, weight: 1 },

  { lat: 52.534725, lng: 13.416654, weight: 2 },
  { lat: 52.53457, lng: 13.41648, weight: 1 },

  { lat: 52.534234, lng: 13.416103, weight: 2 },
  { lat: 52.534147, lng: 13.416009, weight: 1 },

  { lat: 52.533912, lng: 13.415747, weight: 2 },

  { lat: 52.532789, lng: 13.414506, weight: 2 },
];

const kreuzberg = [
  { lat: 52.500031, lng: 13.419207, weight: 30 },

  { lat: 52.500743, lng: 13.419125, weight: 15 },

  { lat: 52.501672, lng: 13.419782, weight: 3 },
  { lat: 52.501807, lng: 13.419877, weight: 4 },

  { lat: 52.502128, lng: 13.420828, weight: 2 },

  { lat: 52.501314, lng: 13.422905, weight: 3 },

  { lat: 52.501994, lng: 13.42042, weight: 1 },

  { lat: 52.502578, lng: 13.420426, weight: 8 },

  { lat: 52.504519, lng: 13.421405, weight: 32 },

  { lat: 52.503855, lng: 13.420248, weight: 7 },

  { lat: 52.503797, lng: 13.41928, weight: 32 },

  { lat: 52.503854, lng: 13.418827, weight: 1 },

  { lat: 52.504955, lng: 13.418664, weight: 31 },

  { lat: 52.503802, lng: 13.418111, weight: 6 },

  { lat: 52.503691, lng: 13.418517, weight: 1 },

  { lat: 52.503443, lng: 13.419428, weight: 12 },

  { lat: 52.50332, lng: 13.419877, weight: 1 },

  { lat: 52.502666, lng: 13.418825, weight: 7 },

  { lat: 52.500999, lng: 13.42072, weight: 6 },
  { lat: 52.50093, lng: 13.420973, weight: 5 },

  { lat: 52.500777, lng: 13.421539, weight: 3 },

  { lat: 52.500558, lng: 13.422334, weight: 8 },

  { lat: 52.500535, lng: 13.422789, weight: 1 },

  { lat: 52.500089, lng: 13.424032, weight: 3 },
  { lat: 52.5, lng: 13.424355, weight: 1 },

  { lat: 52.499818, lng: 13.425019, weight: 8 },

  { lat: 52.500069, lng: 13.422574, weight: 1 },

  { lat: 52.500273, lng: 13.422437, weight: 2 },

  { lat: 52.50045, lng: 13.421814, weight: 6 },
  { lat: 52.500512, lng: 13.421588, weight: 1 },

  { lat: 52.500645, lng: 13.421107, weight: 15 },
  { lat: 52.500715, lng: 13.420853, weight: 1 },
  { lat: 52.500771, lng: 13.420649, weight: 22 },
];

const linienstrasse = [
  { lat: 52.52802, lng: 13.406439, weight: 1 },
  { lat: 52.528439, lng: 13.406871, weight: 2 },

  { lat: 52.528334, lng: 13.407837, weight: 1 },
  { lat: 52.5283, lng: 13.407963, weight: 1 },
  { lat: 52.528258, lng: 13.408367, weight: 2 },
  { lat: 52.528225, lng: 13.408616, weight: 6 },
  { lat: 52.525916, lng: 13.406963, weight: 3 },
  { lat: 52.527933, lng: 13.408026, weight: 1 },
  { lat: 52.527556, lng: 13.408275, weight: 1 },

  { lat: 52.526773, lng: 13.407654, weight: 1 },

  { lat: 52.526485, lng: 13.407406, weight: 2 },

  { lat: 52.525999, lng: 13.407027, weight: 3 },

  { lat: 52.525417, lng: 13.406575, weight: 2 },

  { lat: 52.52572, lng: 13.407204, weight: 1 },
  { lat: 52.525812, lng: 13.407285, weight: 2 },
  { lat: 52.525924, lng: 13.407379, weight: 6 },
  { lat: 52.526032, lng: 13.407469, weight: 3 },

  { lat: 52.52647, lng: 13.40783, weight: 11 },

  { lat: 52.526711, lng: 13.408029, weight: 1 },

  { lat: 52.528331, lng: 13.409432, weight: 6 },

  { lat: 52.528262, lng: 13.40989, weight: 9 },

  { lat: 52.528468, lng: 13.408252, weight: 1 },

  { lat: 52.528657, lng: 13.406712, weight: 1 },

  { lat: 52.52874, lng: 13.406027, weight: 2 },

  { lat: 52.528591, lng: 13.405534, weight: 5 },
  { lat: 52.528556, lng: 13.405912, weight: 14 },
];

export default [
  ...kastanienalle,
  ...sredzkistrasse,
  ...kollwitzstrasse,
  ...kreuzberg,
  ...linienstrasse
];
