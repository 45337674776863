import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import settings from "../../settings";
import SignedUp from "../../components/SignedUp";

const stripePromise = loadStripe(settings.stripePublishKey);

const SignedUpPage = () => {
  let location = useLocation();
  let queryValues = queryString.parse(location.search);
  const companyEmail = queryValues.email;
  const companyName = queryValues.name;
  const personName = queryValues.person;
  return (
    <Elements stripe={stripePromise}>
      <SignedUp
        companyEmail={companyEmail}
        companyName={companyName}
        personName={personName}
      />
    </Elements>
  );
};

export default SignedUpPage;
