import { z } from "zod";

import { OpeningHours } from "../types";
import { denizenApi, denizenApiSecured, EndpointResult } from "./denizen";
import { handleApiError } from "./errorHandling";

export const getOpeningHours = async (
  locationId: string
): Promise<EndpointResult<OpeningHours>> => {
  try {
    const res = await denizenApi.get(`/api/locations/opening-hours`);
    const daySchema = z
      .object({
        from: z.string(),
        to: z.string(),
        note: z.string().nullable(),
      })
      .or(
        z.object({
          from: z.null(),
          to: z.null(),
          note: z.string().nullable(),
        })
      );
    const schema = z.array(
      z.object({
        locationId: z.string().nullable(),
        openingHours: z.object({
          monday: daySchema,
          tuesday: daySchema,
          wednesday: daySchema,
          thursday: daySchema,
          friday: daySchema,
          saturday: daySchema,
          sunday: daySchema,
        }),
      })
    );
    const output = schema.parse(res.data);
    const outputByLocation = output.find(
      (location: any) => location.locationId === locationId
    )?.openingHours;

    return {
      axiosResponse: res,
      data: outputByLocation,
    };
  } catch (e) {
    return handleApiError(e);
  }
};

export const setOpeningHours = async (
  locationId: string,
  data: OpeningHours
): Promise<EndpointResult<undefined>> => {
  const res = await denizenApiSecured().put(
    `/api/locations/${locationId}/opening-hours`,
    {
      ...data,
    }
  );
  return { axiosResponse: res };
};
