import React from "react";
import DownloadInstructions from "../../components/DownloadInstructions";

const AllSetPage = () => {
  return (
    <>
      <DownloadInstructions
        instructions={"Just download our app and become denizen!"}
        title={"You are all set!"}
      />
    </>
  );
};

export default AllSetPage;
