import { GlobalStyles } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import Header from "../Header";

const NotAuthLayout: React.FC = ({ children }) => (
  <>
    <GlobalStyles
      styles={(theme) => ({
        "#root": {
          display: "flex",
        },
      })}
    />
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Header />
      {children}
    </Box>
  </>
);

export default NotAuthLayout;
