import axios, { AxiosResponse } from "axios";
import store from "redux/store";
import config from "../settings";

const baseURL = config.denizenApiBaseUrl;

export const denizenApi = axios.create({
  baseURL,
});

export const denizenApiSecured = () => {
  const state = store.getState();
  return axios.create({
    baseURL,
    headers: {
      "denizen-authorization": state.auth.token,
    },
  });
};

export interface EndpointResult<T> {
  axiosResponse?: AxiosResponse<any>;
  error?: { message: string };
  data?: T;
}
