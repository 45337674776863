import {denizenApi, EndpointResult} from "./denizen";
import {handleApiError} from "./errorHandling";

export const syncProductCodes = async (): Promise<EndpointResult<undefined>> => {
    try {
        const res = await denizenApi.put("/api/products/codes/sync?size=30");
        return { axiosResponse: res };
    } catch (e) {
        return handleApiError(e);
    }
};
