import React from "react";

import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

import Header, { HEADER_HEIGHT } from "components/Layout/Header";
import Footer from "components/Layout/Footer";
import MiddleContent from "./MiddleContent";
import Menu from "./Menu";
import Box from "@mui/material/Box";
import { useMutation } from "api/useMutation";
import { getUserProfile } from "api/getUserProfile";
import { useSnackbar } from "notistack";

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Layout: React.FC = ({ children }) => {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const getProfile = useMutation(getUserProfile);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    getProfile().then((res) => {
      if (res.error) {
        enqueueSnackbar("You have been logged out");
      }
    });
  }, []);

  return (
    <>
      <Header />
      <Box sx={{ display: "flex" }}>
        <Drawer variant="permanent" open={open}>
          <Menu />
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: `calc(100vh - ${HEADER_HEIGHT}px)`,
            overflow: "auto",
          }}
        >
          <MiddleContent>{children}</MiddleContent>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default Layout;
