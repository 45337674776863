import { LocationCodesForm } from "types";
import { denizenApiSecured, EndpointResult } from "./denizen";

export const getLocationAccessCode = async (
  id: string
): Promise<EndpointResult<undefined>> => {
  const res = await denizenApiSecured().get(`/api/locations/${id}/access`);
  return { axiosResponse: res };
};
export const postLocationAccessCode = async (
  values: LocationCodesForm,
  id: string
): Promise<EndpointResult<undefined>> => {
  const res = await denizenApiSecured().post(`/api/locations/${id}/access`, {
    code: values.numa,
  });
  return { axiosResponse: res };
};
