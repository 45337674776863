import React, { useState } from "react";

import { CheckCircle } from "react-feather";
import CardForm from "../CardForm";
import DownloadIcons from "../DownloadIcons";

const SignedUp = ({ companyName, personName, companyEmail }) => {
  const [cardCreated, setCardCreated] = useState(false);
  const invalidUrl = !companyName || !personName || !companyEmail;

  const supportSection = (
    <>
      Support
      <br />
      If you have any questions regarding your denizen account, please contact
      <a href="mailto:info@thisisdenizen.com" className="supportLink">
        {" "}
        info@thisisdenizen.com.
      </a>
      <br />
      <br />
      <br />
      Kind regards,
      <br />
      James Denizen
    </>
  );

  if (invalidUrl) {
    return (
      <>
        <h1>Invalid URL</h1>
        <div className={"border"} />
        <div className={"pageContent"}>
          Looks like the URL you entered is invalid. Make sure you followed the
          link in your email correctly.
          <br />
          <br />
          {supportSection}
        </div>
      </>
    );
  }
  return (
    <>
      <CheckCircle
        width={80}
        height={80}
        color={"#05BD6B"}
        className="centered-horizontal"
      />
      <h1>Your company account has been created.</h1>
      <div className={"border"} />
      <div className={"pageContent"}>
        <strong>{`Dear ${personName},`}</strong>
        <br />
        <br />
        We are happy to inform you that your {companyName} account has been
        created.
        <br />
        <br />
        {cardCreated ? (
          <>
            Your method of payment has been successfully added. In case you
            didn't already, download our mobile application here below.
            <DownloadIcons />
          </>
        ) : (
          <>
            Now all we need is for you to set up a method of payment and you
            will be able to take full advantage of everything denizen has to
            offer:
            <CardForm
              companyName={companyName}
              companyEmail={companyEmail}
              onCardCreated={() => setCardCreated(true)}
            />
          </>
        )}
        {supportSection}
      </div>
    </>
  );
};

export default SignedUp;
