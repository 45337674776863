import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import { TimePicker } from "formik-mui-lab";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";

import { getOpeningHours, setOpeningHours } from "api/openingHours";
import { OpeningHours as OpeningHoursType } from "types";
import ErrorBanner from "components/ErrorBanner";
import { useMutation } from "api/useMutation";
import {
  convertFormDataToOpeningHoursType,
  createInitialValues,
  FormData,
  daysInWeek,
  validate,
} from "./functions";
import config from "settings";

const OpeningHours: React.FC = () => {
  const {
    eiswerkLocationId,
    eiswekKitchenId,
    artolKitchenId,
    artolLocationId,
  } = config;

  const [activeTab, setActiveTab] = useState<string>(eiswerkLocationId);
  const [data, setData] = useState<OpeningHoursType | undefined>();
  const [loadingError, setLoadingError] = useState(false);

  const setOpeningHoursMutation = useMutation(setOpeningHours);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setData(undefined);
    getOpeningHours(activeTab).then((res) => {
      if (res.data) {
        setData(res.data);
        setLoadingError(false);
      } else {
        setLoadingError(true);
      }
    });
  }, [activeTab]);

  if (loadingError) {
    return <ErrorBanner />;
  }

  if (!data) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h4">Opening hours</Typography>
      <Tabs
        value={activeTab}
        onChange={(event, value) => setActiveTab(value)}
        aria-label="opening hours"
      >
        <Tab
          sx={{ textTransform: "none" }}
          label="Eiswerk House"
          value={eiswerkLocationId}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label="Eiswerk Kitchen"
          value={eiswekKitchenId}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label="Artol House"
          value={artolLocationId}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label="Artol Kitchen"
          value={artolKitchenId}
        />
      </Tabs>
      <Box py={2} />
      <Formik
        initialValues={createInitialValues(data)}
        validate={validate}
        onSubmit={async (values: FormData, { setSubmitting }) => {
          const converted = convertFormDataToOpeningHoursType(values);
          const result = await setOpeningHoursMutation(activeTab, converted);
          if (result.error) {
            enqueueSnackbar("An error ocurred.");
          } else {
            enqueueSnackbar("Changes were saved.");
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {daysInWeek.map((dayName) => (
                  <React.Fragment key={dayName}>
                    <Typography
                      variant="body1"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {dayName}
                    </Typography>
                    <Grid container spacing={2} sx={{ mt: -0.5 }}>
                      <Grid item sm={12} md={6}>
                        <Field
                          component={TimePicker}
                          label="From"
                          name={`${dayName}From`}
                          TextFieldProps={{}}
                          ampm={false}
                        />
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <Field
                          component={TimePicker}
                          label="To"
                          name={`${dayName}To`}
                          TextFieldProps={{}}
                          ampm={false}
                        />
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <Field
                          component={TextField}
                          label="Note"
                          name={`${dayName}Note`}
                          TextFieldProps={{}}
                        />
                      </Grid>
                    </Grid>
                    <Box py={2} />
                  </React.Fragment>
                ))}
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default OpeningHours;
