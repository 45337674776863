import React, { useState } from "react";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { setCompanyCreditCard } from "../../api/stripe";

import "./styles.css";
import { Button, CircularProgress } from "@mui/material";

const CardForm = ({ companyName, companyEmail, onCardCreated }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = {
    style: {
      base: {
        fontSize: 16,
        color: "#595959",
        letterSpacing: "0.025em",
        fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
        "::placeholder": {
          color: "#595959",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
    hidePostalCode: true,
  };

  const [error, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    //Create payment method
    setErrors("");
    setLoading(true);
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    if (!!payload.error) {
      setErrors(payload.error.message ?? "Adding payment method failed.");
      setLoading(false);
    } else {
      try {
        await setCompanyCreditCard(
          payload.paymentMethod.id,
          companyEmail,
          companyName
        );
        onCardCreated();
        setLoading(false);
      } catch (e) {
        console.error(e);
        setErrors("Adding credit card failed");
        setLoading(false);
      }
    }
  };

  const loadingBar = (
    <div className={"progress-container"}>
      <CircularProgress className={"centered-horizontal"} />
    </div>
  );
  if (!stripe || !elements) {
    return loadingBar;
  }

  return (
    <form className={"cardForm"} onSubmit={handleSubmit}>
      {error && (
        <>
          <span className={"errorText"}>{error}</span>
          <br />
        </>
      )}
      <div className={"form-input"}>
        <label>
          Card number
          <CardNumberElement options={options} />
        </label>
      </div>
      <div className={"form-input"}>
        <label>
          Expiry
          <CardExpiryElement options={options} />
        </label>
      </div>
      <div className={"form-input"}>
        <label>
          CVC
          <CardCvcElement options={options} />
        </label>
      </div>
      {loading ? (
        loadingBar
      ) : (
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disableElevation
        >
          Save
        </Button>
      )}
    </form>
  );
};

export default CardForm;
