import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { WeeklyMenu } from "types";
import { TextField } from "formik-mui";
import { DateTimePicker } from "formik-mui-lab";
import { useSnackbar } from "notistack";

import { Box, Button, Grid, Typography } from "@mui/material";

import ErrorBanner from "components/ErrorBanner";
import { getWeeklyMenu, setWeeklyMenu } from "api/weeklyMenu";
import { useMutation } from "api/useMutation";

const WeeklyMenuForm: React.FC = () => {
  const setWeeklyMenuMutation = useMutation(setWeeklyMenu);

  const [initialVaues, setInitialValues] = useState<WeeklyMenu | undefined>();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingError, setLoadingError] = useState(false);

  useEffect(() => {
    getWeeklyMenu().then((res) => {
      if (res.data) {
        setInitialValues(res.data);
        setLoadingError(false);
      } else {
        setLoadingError(true);
      }
    });
  }, []);

  if (loadingError) {
    return <ErrorBanner />;
  }

  if (!initialVaues) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h4">Weekly menu</Typography>
      <Box py={2} />
      <Formik
        initialValues={initialVaues}
        onSubmit={async (values: WeeklyMenu, { setSubmitting }) => {
          const result = await setWeeklyMenuMutation(values);
          if (result.error) {
            enqueueSnackbar("An error ocurred.");
          } else {
            enqueueSnackbar("Changes were saved.");
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="monday"
                  label="Monday"
                  component={TextField}
                  fullWidth
                />
                <ErrorMessage name="monday" component="div" />
                <Box py={1} />
                <Field
                  name="tuesday"
                  label="Tuesday"
                  component={TextField}
                  fullWidth
                />
                <ErrorMessage name="tuesday" component="div" />
                <Box py={1} />
                <Field
                  name="wednesday"
                  label="Wednesday"
                  component={TextField}
                  fullWidth
                />
                <ErrorMessage name="wednesday" component="div" />
                <Box py={1} />
                <Field
                  name="thursday"
                  label="Thursday"
                  component={TextField}
                  fullWidth
                />
                <ErrorMessage name="thursday" component="div" />
                <Box py={1} />
                <Field
                  name="friday"
                  label="Friday"
                  component={TextField}
                  fullWidth
                />
                <ErrorMessage name="friday" component="div" />
                <Box py={1} />
                <Field
                  name="soup"
                  label="Soup"
                  component={TextField}
                  fullWidth
                />
                <ErrorMessage name="soup" component="div" />
                <Box py={1} />
                <Field
                  component={DateTimePicker}
                  label="Start"
                  name="publication"
                  inputFormat="dd MMM yyyy HH:mm"
                  TextFieldProps={{
                    fullWidth: true,
                  }}
                />
                <ErrorMessage name="publication" component="div" />
                <Box py={1} />
                <Field
                  component={DateTimePicker}
                  label="End"
                  name="expiration"
                  inputFormat="dd MMM yyyy HH:mm"
                  TextFieldProps={{
                    fullWidth: true,
                  }}
                />
                <ErrorMessage name="expiration" component="div" />
                <Box py={1} />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default WeeklyMenuForm;
