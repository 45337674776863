export const apiEnv = process.env.REACT_APP_API_ENV;

const sharedConfig = {
  stripeApiKey:
    "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",
  stripePublishKey:
    "pk_live_51ILlSOIgjWbkP4XittRHDhsSybc89UW35f3Zw9CNZ0yTwWZ6pcuZ2N9ZcSl1N8W4sVQcfzkWyBrAGtKZ8foi9WVN00r8alkYk7",
  googleApiKey: "AIzaSyDha3hsKSZtKe5wCLCSs-2NcxKPzpb5mm4",
};

const config = {
  ...sharedConfig,
  denizenApiBaseUrl: "",
  numaLocationId: "62f229ae4586dc76ed535d8f",
  fileDownloadPrefix:
    "https://cdn-storage-bucket.s3.eu-central-1.amazonaws.com/",
  eiswerkLocationId: "5f38051e9e9af801537beea6",
  artolLocationId: "64a56872f10bbc38f9e7fa66",
  artolKitchenId: "artol-kitchen",
  eiswekKitchenId: "kitchen",
};

if (apiEnv === "prod") {
  config.denizenApiBaseUrl = "https://api.denizenplatform.com";
  config.numaLocationId = "6312112eaafdbc43467c4bd9";
  config.fileDownloadPrefix =
    "https://cdn-storage-bucket-prod.s3.eu-central-1.amazonaws.com/";
  config.eiswerkLocationId = "5d1bcda0dbd6e40010479eec";
  config.artolLocationId = "64d634a0232fa8a331106dbd";
} else if (apiEnv == "dev") {
  config.denizenApiBaseUrl = "https://localhost:5001";
} else {
  config.denizenApiBaseUrl = "https://api.test.denizenplatform.com";
}

export default config;

export const contactsConfig = {
  ["hqContacts"]: [
    {
      label: "Space supply",
      name: "spaceSupplyMemberId",
    },
    {
      label: "Technical issues",
      name: "technicalIssuesMemberId",
    },
    {
      label: "Space demand",
      name: "spaceDemandMemberId",
    },
    {
      label: "Investors, etc.",
      name: "investorsMemberId",
    },
    {
      label: "Feedback",
      name: "feedbackMemberId",
    },
  ],

  ["eiswerkContacts"]: [
    {
      label: "Eiswerk membership",
      name: "eiswerkMembershipsMemberId",
    },
    {
      label: "Eiswerk community events",
      name: "eiswerkCommunityEventsMemberId",
    },
    {
      label: "Eiswerk self care",
      name: "eiswerkSelfCareMemberId",
    },
    {
      label: "Spaces ops",
      name: "eiswerkSpacesOpsMemberId",
    },
  ],

  ["artolContacts"]: [
    {
      label: "Artol membership",
      name: "artolMembershipsMemberId",
    },
    {
      label: "Artol community events",
      name: "artolCommunityEventsMemberId",
    },
    {
      label: "Spaces ops",
      name: "artolSpacesOpsMemberId",
    },
  ],
  ["kitchenDuty"]: [
    {
      label: "Eiswerk Kitchen",
      name: "eiswerkKitchenDutyMemberId",
    },
    {
      label: "Artol Kitchen",
      name: "artolKitchenDutyMemberId",
    },
  ],
};
