import { DateTime } from "luxon";

import { OpeningHours as OpeningHoursType } from "types";

export const daysInWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

type FormDataDays = {
  mondayFrom: DateTime | null;
  mondayTo: DateTime | null;
  tuesdayFrom: DateTime | null;
  tuesdayTo: DateTime | null;
  wednesdayFrom: DateTime | null;
  wednesdayTo: DateTime | null;
  thursdayFrom: DateTime | null;
  thursdayTo: DateTime | null;
  fridayFrom: DateTime | null;
  fridayTo: DateTime | null;
  saturdayFrom: DateTime | null;
  saturdayTo: DateTime | null;
  sundayFrom: DateTime | null;
  sundayTo: DateTime | null;
};

type FormDataNotes = {
  mondayNote: string | null;
  tuesdayNote: string | null;
  wednesdayNote: string | null;
  thursdayNote: string | null;
  fridayNote: string | null;
  saturdayNote: string | null;
  sundayNote: string | null;
};

export type FormData = FormDataDays & FormDataNotes;

export const convertFormDataToOpeningHoursType = (formData: FormData) => {
  const getTimeObject = (
    from: DateTime | null,
    to: DateTime | null,
    note: string | null
  ) => {
    if (from === null || to === null) {
      return {
        from: null,
        to: null,
        note,
      };
    }
    return {
      from: `${from.hour}:${from.minute.toString().padStart(2, "0")}`,
      to: `${to.hour}:${to.minute.toString().padStart(2, "0")}`,
      note,
    };
  };
  return {
    monday: getTimeObject(
      formData.mondayFrom,
      formData.mondayTo,
      formData.mondayNote
    ),
    tuesday: getTimeObject(
      formData.tuesdayFrom,
      formData.tuesdayTo,
      formData.tuesdayNote
    ),
    wednesday: getTimeObject(
      formData.wednesdayFrom,
      formData.wednesdayTo,
      formData.wednesdayNote
    ),
    thursday: getTimeObject(
      formData.thursdayFrom,
      formData.thursdayTo,
      formData.thursdayNote
    ),
    friday: getTimeObject(
      formData.fridayFrom,
      formData.fridayTo,
      formData.fridayNote
    ),
    saturday: getTimeObject(
      formData.saturdayFrom,
      formData.saturdayTo,
      formData.saturdayNote
    ),
    sunday: getTimeObject(
      formData.sundayFrom,
      formData.sundayTo,
      formData.sundayNote
    ),
  };
};

export const createInitialValues = (openingHours: OpeningHoursType) => {
  const createTime = (time: string | null) => {
    if (time === null) {
      return null;
    }
    const timeSeparated = time.split(":");
    let dt = DateTime.now();
    dt = dt.set({ hour: parseInt(timeSeparated[0], 10) });
    dt = dt.set({ minute: parseInt(timeSeparated[1], 10) });
    return dt;
  };

  return {
    mondayFrom: createTime(openingHours.monday.from),
    mondayTo: createTime(openingHours.monday.to),
    mondayNote: openingHours.monday.note,
    tuesdayFrom: createTime(openingHours.tuesday.from),
    tuesdayTo: createTime(openingHours.tuesday.to),
    tuesdayNote: openingHours.tuesday.note,
    wednesdayFrom: createTime(openingHours.wednesday.from),
    wednesdayTo: createTime(openingHours.wednesday.to),
    wednesdayNote: openingHours.wednesday.note,
    thursdayFrom: createTime(openingHours.thursday.from),
    thursdayTo: createTime(openingHours.thursday.to),
    thursdayNote: openingHours.thursday.note,
    fridayFrom: createTime(openingHours.friday.from),
    fridayTo: createTime(openingHours.friday.to),
    fridayNote: openingHours.friday.note,
    saturdayFrom: createTime(openingHours.saturday.from),
    saturdayTo: createTime(openingHours.saturday.to),
    saturdayNote: openingHours.saturday.note,
    sundayFrom: createTime(openingHours.sunday.from),
    sundayTo: createTime(openingHours.sunday.to),
    sundayNote: openingHours.sunday.note,
  };
};

export const validate = (values: FormData) => {
  const errors: {
    mondayFrom?: string;
    mondayTo?: string;
    tuesdayFrom?: string;
    tuesdayTo?: string;
    wednesdayFrom?: string;
    wednesdayTo?: string;
    thursdayFrom?: string;
    thursdayTo?: string;
    fridayFrom?: string;
    fridayTo?: string;
    saturdayFrom?: string;
    saturdayTo?: string;
    sundayFrom?: string;
    sundayTo?: string;
  } = {};
  daysInWeek.forEach((dayName) => {
    const fieldFromName = `${dayName}From` as keyof FormDataDays;
    const fieldToName = `${dayName}To` as keyof FormDataDays;
    const fromValue = values[fieldFromName];
    const toValue = values[fieldToName];
    if (fromValue && !fromValue.isValid) {
      errors[fieldFromName] = "Not a valid time";
    }
    if (toValue && !toValue.isValid) {
      errors[fieldToName] = "Not a valid time";
    }
  });
  return errors;
};
