import React from "react";

import { Box, Typography, List, Divider } from "@mui/material";
import FileUpload from "components/FileUpload";

const Files: React.FC = () => (
  <Box>
    <Typography variant="h4">Files</Typography>
    <Box py={2} />
    <List>
      <FileUpload
        name="Host your space"
        filePath="assets/Denizen Host Your Space Deck.pdf"
      />
      <Divider />
      <FileUpload
        name="Flexible workspace solutions"
        filePath="assets/Denizen Need Space Deck.pdf"
      />
      <Divider />
      <FileUpload
        name="Scalable workspace solutions"
        filePath="assets/Denizen Scalable (Work)space Solutions.pdf"
      />
      <Divider />
      <FileUpload
        name="Business address"
        filePath="assets/Denizen Business Address Offer.pdf"
      />
      <Divider />
      <FileUpload name="Catering menu" filePath="settings/cateringMenu.pdf" />
      <Divider />
    </List>
  </Box>
);

export default Files;
