import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import SignedUpPage from "./pages/SignedUpPage";
import AllSetPage from "./pages/AllSetPage";
import GenerateUrlPage from "./pages/GenerateUrlPage";
import WeeklyMenuForm from "./pages/WeeklyMenu";
import Products from "./pages/Products";
import HeatMapPage from "./pages/HeatMapPage";
import OpeningHours from "pages/OpeningHours";
import SignIn from "pages/SignIn";
import Layout from "components/Layout";
import { useAppSelector } from "redux/store";
import { selectAuthToken } from "redux/authSelector";
import NotAuthLayout from "components/Layout/NotAuthLayout";
import AccessFrom from "pages/Access";
import Contacts from "pages/Contacts";
import Files from "pages/Files";

const Routes: React.FC = () => {
  const authToken = useAppSelector(selectAuthToken);
  return (
    <Router>
      {!!authToken ? (
        <Layout>
          <Switch>
            <Route path="/generate-url">
              <GenerateUrlPage />
            </Route>
            <Route path="/all-set">
              <AllSetPage />
            </Route>
            <Route path="/signed-up">
              <SignedUpPage />
            </Route>
            <Route path="/heatmap">
              <HeatMapPage />
            </Route>
            <Route path="/openingHours">
              <OpeningHours />
            </Route>
            <Route path="/products">
              <Products />
            </Route>
            <Route path="/access">
              <AccessFrom />
            </Route>
            <Route path="/weekly-menu">
              <WeeklyMenuForm />
            </Route>
            <Route path="/files">
              <Files />
            </Route>
            <Route path="/">
              <Contacts />
            </Route>
          </Switch>
        </Layout>
      ) : (
        <NotAuthLayout>
          <Switch>
            <Route path="/">
              <SignIn />
            </Route>
          </Switch>
        </NotAuthLayout>
      )}
    </Router>
  );
};

export default Routes;
