import {Box, Button, Container, Typography} from "@mui/material";
import React from "react";
import {syncProductCodes} from "../../api/products";
import {useSnackbar} from "notistack";


const Products: React.FC = () => {

    const [qrCodesLoading, setQrCodesLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();


    const handleQRCodes = async () => {
        setQrCodesLoading(true)
        const data = await syncProductCodes()
        if(data.axiosResponse?.status === 200) {
            enqueueSnackbar('The QR codes are synced')
        } else {
            enqueueSnackbar('The QR codes were not sync successfully')
        }
        setQrCodesLoading(false)
    }
    return (
        <Container>
            <Typography variant="h4">QR codes</Typography>
            <Box py={2}/>
            <Button
                type="submit"
                disabled={qrCodesLoading}
                variant="contained"
                onClick={handleQRCodes}
            >
              {qrCodesLoading ? 'Syncing...' : 'Sync'}
            </Button>
        </Container>
    )
}

export default Products
