import React, { useState } from "react";
import queryString from "query-string";
import { Button, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./styles.css";

const Root = styled("div")(({ theme }) => ({
  maxWidth: "300px",
  width: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  "& > *": {
    margin: theme.spacing(1),
    width: "25ch",
  },
}));

const GenerateUrlForm = () => {
  const [personName, setPersonName] = useState<string | undefined>();
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [showvalidation, setShowValidation] = useState(false);
  const [generatedUrl, setGeneratedUrl] = useState("");

  const handleSubmit = () => {
    if (!personName || !companyEmail || !companyName) {
      setShowValidation(true);
      return;
    }
    var queryParameters = queryString.stringify({
      email: companyEmail,
      name: companyName,
      person: personName,
    });
    setShowValidation(false);
    const url = `${window.location.protocol}//${window.location.host}/signed-up?${queryParameters}`;
    navigator.clipboard.writeText(url);
    setGeneratedUrl(url);
  };

  return (
    <>
      <h1>Generate payment url</h1>
      <div className={"border"} />
      <div className={"pageContent"}>
        Fill in the form below to generate URL. Send this URL to newly signed-up
        company so they can add their credit card info.
        <Root>
          <TextField
            label={"Person name"}
            className={"textInput"}
            onChange={(event) => setPersonName(event.target.value)}
            error={showvalidation && !personName}
            helperText={
              showvalidation && !personName ? "Field is mandatory" : null
            }
          />
          <TextField
            label={"Company name"}
            className={"textInput"}
            onChange={(event) => setCompanyName(event.target.value)}
            error={showvalidation && !companyName}
            helperText={
              showvalidation && !companyName ? "Field is mandatory" : null
            }
          />
          <TextField
            label={"Company email"}
            className={"textInput"}
            onChange={(event) => setCompanyEmail(event.target.value)}
            error={showvalidation && !companyEmail}
            helperText={
              showvalidation && !companyEmail ? "Field is mandatory" : null
            }
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disableElevation
          >
            Generate and copy
          </Button>
        </Root>
        {generatedUrl && (
          <>
            <br />
            <span>Generated URL has been copied to clipboard:</span>
            <br />
            <span className="genreatedUrl">{generatedUrl}</span>
          </>
        )}
      </div>
    </>
  );
};

export default GenerateUrlForm;
