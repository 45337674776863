import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import HeatmapData from "../../data/heatmap";
import settings from "../../settings";
import { Switch, Slider, Typography, FormControlLabel } from "@mui/material";

const HeatMap = () => {
  const [providers, setProviders] = useState(true);
  const [consumers, setConsumers] = useState(false);
  const [radius, setRadius] = useState(40);
  const [opacity, setOpacity] = useState(0.6);
  const [providersWeight, setProvidersWeight] = useState(5);

  const defaultProps = {
    center: {
      lat: 52.52132415996851,
      lng: 13.412502760200821,
    },
    zoom: 13,
  };

  const heatMapData = {
    positions: [
      ...(consumers ? HeatmapData.consumers : []),
      ...(providers
        ? HeatmapData.providers.map((x) => {
            return {
              lat: x.lat,
              lng: x.lng,
              weight: x.weight * providersWeight,
            };
          })
        : []),
    ],
    options: {
      radius: radius,
      opacity: opacity,
    },
  };
  return (
    <>
      <h1>Heatmap</h1>
      <div className={"border"} />
      <div className={"pageContent"}>
        <Typography id="radius-slider" gutterBottom>
          Radius
        </Typography>
        <Slider
          value={radius}
          onChange={(i, value) => setRadius(value)}
          valueLabelDisplay="auto"
          aria-labelledby="radius-slider"
        />
        <Typography id="opacity-slider" gutterBottom>
          Opacity
        </Typography>
        <Slider
          value={opacity}
          onChange={(i, value) => setOpacity(value)}
          valueLabelDisplay="auto"
          aria-labelledby="opacity-slider"
          min={0}
          max={1}
          step={0.1}
        />
        <Typography id="weight-slider" gutterBottom>
          Weight of 1 company = Weight of X citizens
        </Typography>
        <Slider
          value={providersWeight}
          onChange={(i, value) => setProvidersWeight(value)}
          valueLabelDisplay="auto"
          aria-labelledby="weight-slider"
          min={1}
          max={20}
          step={0.5}
        />
        <FormControlLabel
          control={
            <Switch
              checked={providers}
              onChange={() => setProviders(!providers)}
              name="Providers"
              color="primary"
            />
          }
          label="Providers"
        />
        <FormControlLabel
          control={
            <Switch
              checked={consumers}
              onChange={() => setConsumers(!consumers)}
              name="Consumers"
              color="secondary"
            />
          }
          label="Consumers"
        />
        <div style={{ height: "60vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: settings.googleApiKey,
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            heatmap={heatMapData}
            heatmapLibrary={true}
          ></GoogleMapReact>
        </div>
      </div>
    </>
  );
};

export default HeatMap;
