import { Box, Typography } from "@mui/material";

import logo from "assets/logo.svg";
import { apiEnv } from "settings";

import "./styles.css";

export const HEADER_HEIGHT = 50;

const Header = () => (
  <Box
    sx={(theme) => ({ backgroundColor: theme.colors.black })}
    display="flex"
    alignItems={"center"}
  >
    <Box
      sx={(theme) => ({ backgroundColor: theme.colors.white })}
      display="flex"
      flexShrink={1}
    >
      <img src={logo} alt={"logo"} className={"logo"} />
    </Box>
    <Typography sx={(theme) => ({ color: theme.colors.white })}>
      {apiEnv === "prod" ? "Production environment" : "Test environment"}
    </Typography>
  </Box>
);

export default Header;
