import { ContactSettings } from "../types";
import { denizenApiSecured, EndpointResult } from "./denizen";
import { handleApiError } from "./errorHandling";

export const uploadFile = async (
  filePath: string,
  file: any
): Promise<EndpointResult<undefined>> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filePath", filePath);
    const res = await denizenApiSecured().post(`/api/files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { axiosResponse: res };
  } catch (e) {
    return handleApiError(e);
  }
};
