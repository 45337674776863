import { z } from "zod";

import { Employee } from "../types";
import { denizenApi, EndpointResult, denizenApiSecured } from "./denizen";
import { handleApiError } from "./errorHandling";

export const getEmployees = async (): Promise<EndpointResult<Employee[]>> => {
  try {
    const res = await denizenApiSecured().get(`/api/members/employees`);
    const schema = z.array(
      z.object({
        id: z.string(),
        name: z.string(),
      })
    );
    const output = schema.parse(res.data);
    return {
      axiosResponse: res,
      data: output,
    };
  } catch (e) {
    return handleApiError(e);
  }
};
