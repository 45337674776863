import React from "react";
import HeatMap from "../../components/HeatMap";

const AllSetPage = () => {
  return (
    <HeatMap />
  );
};

export default AllSetPage;
