import React from "react";
import settings from "../../settings";
import { Box, Button } from "@mui/material";
import { useAppDispatch } from "redux/store";
import { setAuthToken } from "redux/auth";
import { useHistory } from "react-router-dom";

const SignIn: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get("token");
    if (tokenFromUrl) {
      dispatch(setAuthToken(tokenFromUrl));
      history.replace("/");
    }
  }, []);

  return (
    <form
      method="POST"
      action={`${settings.denizenApiBaseUrl}/api/account/external-login?returnUrl=/sign-in`}
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button type="submit">Sign in with Google</Button>
    </form>
  );
};

export default SignIn;
