import React from "react";

import "./styles.css";

const androidDownloadUrl =
  "https://play.google.com/store/apps/details?id=com.denizen";
const iOSDownloadUrl =
  "https://apps.apple.com/us/app/testflight/id899247664";
const androidImageUrl =
  "https://cdn-storage-bucket-prod.s3.eu-central-1.amazonaws.com/GooglePlayBadge.png";
const iOsImageUrl =
  "https://cdn-storage-bucket-prod.s3.eu-central-1.amazonaws.com/AppStoreBadge.png";
const DownloadIcons = () => {
  return (
    <div className="centered-horizontal download-container">
      <a href={androidDownloadUrl}>
        <img src={androidImageUrl} className={"download-image"} alt={"Download denizen from App Store"}/>
      </a>
      <a href={iOSDownloadUrl}>
        <img src={iOsImageUrl} className={"download-image"} alt={"Download denizen from Google Play"}/>
      </a>
    </div>
  );
};

export default DownloadIcons;
