import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { getDenizenTheme } from "./components/Theme";
import { ThemeProvider } from "@mui/material/styles";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { SnackbarProvider } from "notistack";
import store, { persistor } from "./redux/store";
import Routes from "routes";

const theme = getDenizenTheme();

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <CssBaseline />
              <GlobalStyles
                styles={(theme) => ({
                  body: {
                    backgroundColor: theme.colors.pampas,
                    height: "100%",
                  },
                  html: {
                    height: "100%",
                  },
                  "#root": {
                    height: "100%",
                  },
                })}
              />
              <Routes />
            </LocalizationProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

const rootElement = document.getElementById("root");

ReactDOM.render(<App />, rootElement);
