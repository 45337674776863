import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const ErrorBanner: React.FC = () => (
  <Box textAlign={"center"}>
    <Typography variant="h4">An error occurred.</Typography>
    <Typography variant="h6">Please try again later.</Typography>
  </Box>
);

export default ErrorBanner;
