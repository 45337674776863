import { z } from "zod";

import { ContactSettings, OpeningHours, Settings } from "../types";
import { denizenApiSecured, EndpointResult } from "./denizen";
import { handleApiError } from "./errorHandling";

export const getSettings = async (): Promise<EndpointResult<Settings>> => {
  try {
    const res = await denizenApiSecured().get(
      `/api/system-maintenance/settings`
    );
    const contactSettingsSchema = z.object({
      artolKitchenDutyMemberId: z.string().nullable(),
      eiswerkKitchenDutyMemberId: z.string().nullable(),
      // WIP - zod is working only when there at leas some value.

      // spaceSupplyMemberId: z.string().nullable(),
      // technicalMemberId: z.string().nullable(),
      // spaceDemandMemberId: z.string().nullable(),
      // investorsMemberId: z.string().nullable(),
      // feedbackMemberId: z.string().nullable(),
      // eiswerkMembershipMemberId: z.string().nullable(),
      // eiswerkCommunityEventsMemberId: z.string().nullable(),
      // eiswerkSelfCareMemberId: z.string().nullable(),
      // eiswerkSpacesOpsMemberId: z.string().nullable(),
      // artolMembershipMemberId: z.string().nullable(),
      // artolCommunityEventsMemberId: z.string().nullable(),
      // artolSpacesOpsMemberId: z.string().nullable(),
    });
    const schema = z.object({
      contactSettings: contactSettingsSchema,
    });
    const output = res.data;
    schema.parse(output);
    return {
      axiosResponse: res,
      data: output,
    };
  } catch (e) {
    return handleApiError(e);
  }
};

export const setContactSettings = async (
  contactSettings: Partial<ContactSettings>
): Promise<EndpointResult<undefined>> => {
  const res = await denizenApiSecured().put(
    `/api/system-maintenance/settings`,
    {
      contactSettings: contactSettings,
    }
  );
  return { axiosResponse: res };
};
