import { createTheme } from "@mui/material/styles";

const woodsmoke = "#181818";
const pampas = "#F8F7F3";
const white = "#ffffff";
const black = "#000000";

export const getDenizenTheme = () =>
  createTheme({
    colors: {
      woodsmoke,
      pampas,
      white,
      black,
    },
    palette: {
      primary: {
        main: woodsmoke,
      },
    },
  });

declare module "@mui/material/styles" {
  interface Theme {
    colors: {
      woodsmoke: string;
      pampas: string;
      white: string;
      black: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors: {
      woodsmoke: string;
      pampas: string;
      white: string;
      black: string;
    };
  }
}
