import axios from "axios";
import queryString from "query-string";
import settings from "../settings";

const stripeApi = axios.create({
  baseURL: "https://api.stripe.com/v1",
});

stripeApi.defaults.headers.common.Authorization = `Bearer ${settings.stripeApiKey}`;

const handleApiError = (message) => (apiError) => {
  throw new Error(
    `${message} Error: ${apiError?.response?.data?.error?.message}`
  );
};

const getCompanyIdByEmail = async (companyEmail) => {
  const res = await stripeApi
    .get("/customers", {
      params: { email: companyEmail },
    })
    .catch(handleApiError("Getting company by email failed."));

  return res.data.data[0]?.id;
};

const createCompany = async (companyEmail, companyName) => {
  const customerCreationResult = await stripeApi
    .post(
      "/customers",
      queryString.stringify({
        description: companyName,
        name: companyName,
        email: companyEmail,
      })
    )
    .catch(handleApiError("Company creation failed."));

  return customerCreationResult.data.id;
};

const detachExistingPaymentMethods = async (companyId) => {
  const existingPaymentMethodIds = await getPaymentMethodIds(companyId);
  existingPaymentMethodIds.forEach(async (pmId) => {
    await detachPaymentMethod(pmId);
  });
};

const getPaymentMethodIds = async (companyId) => {
  const res = await stripeApi
    .get("/payment_methods", {
      params: { customer: companyId, type: "card" },
    })
    .catch(handleApiError("Getting of payment methods failed."));

  return res.data.data.map((x) => x.id);
};

const attachPaymentMethod = async (companyId, paymentMethodId) => {
  await stripeApi
    .post(
      `/payment_methods/${paymentMethodId}/attach`,
      queryString.stringify({
        customer: companyId,
      })
    )
    .catch(handleApiError("Getting of payment methods failed."));
};

const detachPaymentMethod = async (paymentMethodId) => {
  await stripeApi
    .post(`/payment_methods/${paymentMethodId}/detach`)
    .catch(handleApiError("Getting of payment methods failed."));
};

export const setCompanyCreditCard = async (
  paymentMethodId,
  companyEmail,
  companyName
) => {
  let companyId = await getCompanyIdByEmail(companyEmail);
  if (!companyId) {
    companyId = await createCompany(companyEmail, companyName);
  } else {
    await detachExistingPaymentMethods(companyId);
  }
  await attachPaymentMethod(companyId, paymentMethodId);
};
