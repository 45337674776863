import { DateTime } from "luxon";
import { z } from "zod";

import { denizenApi, denizenApiSecured, EndpointResult } from "./denizen";
import { WeeklyMenu } from "../types";
import { handleApiError } from "./errorHandling";

export const getWeeklyMenu = async (): Promise<EndpointResult<WeeklyMenu>> => {
  try {
    const res = await denizenApi.get("/api/kitchen/weekly-menu");
    const schema = z.object({
      monday: z.string(),
      tuesday: z.string(),
      wednesday: z.string(),
      thursday: z.string(),
      friday: z.string(),
      soup: z.string(),
      expiration: z.string(),
      publication: z.string(),
    });
    const output = res.data;
    schema.parse(output);
    output.expiration = DateTime.fromISO(res.data.expiration);
    return {
      axiosResponse: res,
      data: output,
    };
  } catch (e) {
    return handleApiError(e);
  }
};

export const setWeeklyMenu = async (
  data: WeeklyMenu
): Promise<EndpointResult<undefined>> => {
  const res = await denizenApiSecured().put("/api/kitchen/weekly-menu", {
    ...data,
    expiration: data.expiration.toISO(),
  });
  return { axiosResponse: res };
};
