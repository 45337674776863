import { Box } from "@mui/material";

const MiddleContent: React.FC = ({ children }) => {
  return (
    <Box maxWidth="md" sx={{ py: 4, px: 3 }}>
      {children}
    </Box>
  );
};

export default MiddleContent;
