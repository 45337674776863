import { useHistory } from "react-router-dom";
import { useAppDispatch } from "redux/store";
import { setAuthToken } from "redux/auth";
import { EndpointResult } from "./denizen";

export function useMutation<T extends any[], K>(
  mutation: (...args: T) => Promise<EndpointResult<K>>
) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  return async (...params: T): Promise<EndpointResult<K>> => {
    try {
      return await mutation(...params);
    } catch (e) {
      let errorMessage = "An error occured";

      if ((e as any)?.response?.status === 401) {
        dispatch(setAuthToken(null));
        history.replace("");
      }

      if (e instanceof Error) {
        errorMessage = e.message;
      }
      return { error: { message: errorMessage } };
    }
  };
}
